import React, { useEffect, useState, useCallback } from "react";
import SidebarNav from "../sidebar";
import Collapse from 'react-bootstrap/Collapse';
import { Table } from "antd";
import { toast } from "react-toastify";

import { Link } from "react-router-dom";
import moment from "moment";
import {
  itemRender,
  onShowSizeChange,
} from "../../components/paginationfunction";
import { Button } from "react-bootstrap";
import { GetAllPayments, UpdatePaymentStatus } from "../../../API/PaymentsAPI";

const Payment = () => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState([]);

  const updatePaymentStatus = async (id) => {
    await UpdatePaymentStatus(id, { payment_status: "WITHDRAWN" })
    .then((res) => {
      if (res.status === 200) {
        fetchData();
        toast.success("Payment Status Updated Successfully");
      }
    })
    .catch((err) => {
      toast.error("Error updating payment status");
    });
  };

  const columns = [
    {
      title: "Order Id",
      dataIndex: "short_id",
      render: (text) => <>{text}</>,
    },
    {
      title: "Date",
      dataIndex: "created_at",
      render: (text) => <>{moment(text).format("DD-MM-YYYY, HH:mm")}</>,
    },
    {
      title: "Release date",
      dataIndex: "payment_date",
      render: (text) => <>{moment(text).format("DD-MM-YYYY, HH:mm")}</>,
    },
    {
      title: "User",
      dataIndex: "userUsername",
      render: (text, record) => <>{text}</>,
      sorter: (a, b) => a.user.length - b.user.length,
    },
    {
      title: "Provider",
      dataIndex: "serviceProviderUsername",
      render: (text, record) => <>{text}</>,
      sorter: (a, b) => a.provider.length - b.provider.length,
    },
    {
      title: "Service",
      dataIndex: "serviceType",
      render: (text) => <>{text}</>,
      sorter: (a, b) => a.service.length - b.service.length,
    },
    {
      title: "Gateway",
      dataIndex: "payment_gateway",
      render: (text) => <>{text}</>,
    },
    {
      title: "Payment Status",
      dataIndex: "payment_status",
      render: (text) => <>{text}</>,
    },
    {
      title: "Amount",
      dataIndex: "total_amount",
      render: (text) => <>{text}</>,
    },
    {
      title: "Service Provider Amount",
      dataIndex: "service_provider_amount",
      render: (text) => <>{text}</>,
    },
    {
      title: "Commission",
      dataIndex: "platform_commission",
      render: (text) => <>{text}</>,
    },
    {
      title: "Actions",
      render: (text, record) => (
        <div className="actions">
          <Link
            to={`/admin/payments/${record?.short_id}`}
            className="btn btn-sm bg-success-light"
          >
            <i className="fe fe-eye"></i> View
          </Link>
          {
          record?.payment_status === "PENDING" &&
          <Button className="btn btn-sm bg-danger-light" onClick={() => updatePaymentStatus(record?.payment_id)}>
            Release
          </Button>
          }
          {/* <Button
            onClick={() => this.deleteProvider(record?.serviceProvider_id)}
            variant="link"
            style={{ cursor: "pointer", color: "red" }}
          >
            <FontAwesomeIcon icon={faTrashAlt} />
          </Button> */}
        </div>
      ),
    },
  ];

  const fetchData = useCallback(async () => {
    const resp = await GetAllPayments();
    setData([...resp.data]);
  }, []);


  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <>
      <SidebarNav />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Payments</h3>
              </div>
              <div className="col-auto text-right">
                <button
                  className="btn btn-white filter-btn"
                  onClick={() => setOpen(!open)}
                  href={"#"}
                  id="filter_search"
                >
                  <i className="fas fa-filter">Filter</i>
                </button>
              </div>
            </div>
          </div>

          <Collapse in={open}>
            <div id="example-collapse-text">
              <div className="card filter-card">
                <div className="card-body pb-0">
                  <form>
                    <div className="row filter-row">
                      <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                          <label>Provider</label>
                          <select className="form-control select">
                            <option>Select Provider</option>
                            <option>Thomas Herzberg</option>
                            <option>Matthew Garcia</option>
                            <option>Yolanda Potter</option>
                            <option>Ricardo Flemings</option>
                            <option>Maritza Wasson</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                          <label>Status</label>
                          <select className="form-control select">
                            <option>Select Status</option>
                            <option>Pending</option>
                            <option>Inprogress</option>
                            <option>Completed (Provider)</option>
                            <option>Accepted</option>
                            <option>Rejected</option>
                            <option>Cancelled (Provider)</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                          <label>From Date</label>
                          <div className="cal-icon">
                            <input
                              className="form-control datetimepicker"
                              type="text"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                          <label>To Date</label>
                          <div className="cal-icon">
                            <input
                              className="form-control datetimepicker"
                              type="text"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                          <button className="btn btn-primary btn-block" type="submit">
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Collapse>

          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <Table
                      className="table table-hover table-center mb-0 datatable"
                      style={{ overflowX: "auto", width: "100%" }}
                      columns={columns}
                      dataSource={data}
                      rowKey={(record) => record.payment_id}
                      showSizeChanger={true}
                      pagination={{
                        total: data.length,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Payment;
