import SidebarNav from "../sidebar";
import React, { useEffect, useState } from 'react'
import { GetAllBankDetails } from '../../../API/BankDetailsAPI';


function ServiceProviderBankDetailsList() {
    const [bankDetailsList, setBankDetailsList] = useState([]);

    useEffect(() => {
        GetAllBankDetails().then(res => {
            setBankDetailsList(res.data);
        })
    },[])

  return (
<>
<SidebarNav />
      <div className="page-wrapper">
        <div className="content container-fluid">
                     <div className="page-header">
                         <div className="row">
                             <div className="col">
                                 <h1 className="page-title">Service Provider Bank Details</h1>
                             </div>
                         </div>
                     </div>
                     <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-hover table-center mb-0 datatable">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>SP username</th>
                        <th>Account Holder</th>
                        <th>Account Number</th>
                        <th>IFSC Code</th>
                        <th>Bank Name</th>
                        <th>Branch</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bankDetailsList &&
                        bankDetailsList.map((terms, index) => (
                          <tr key={`terms-${terms.id}`}>
                            <td>{index + 1}</td>
                            <td>{terms.service_provider_username}</td>
                            <td>{terms.account_holder_name}</td>
                            <td>{terms.account_number}</td>
                            <td>{terms.ifsc_code}</td>
                            <td>{terms.bank_name}</td>
                            <td>{terms.branch}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
</>
  )
}

export default ServiceProviderBankDetailsList