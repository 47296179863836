import React, { useEffect, useState } from 'react';
import { Table, Button } from 'react-bootstrap';
import SidebarNav from "../sidebar";
import QueryDetails from './QueryDetails';
import { getGroupedChatsAdmin } from '../../../API/QueryChatAPI';

const QueryMsg = () => {
  const [queries, setQueries] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedQuery, setSelectedQuery] = useState(null);
  const [loading, setLoading] = useState(true);

  const handleViewDetails = (query) => {
    setSelectedQuery(query);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedQuery(null);
  };

  const fetchQueries = async () => {
    try {
      setLoading(true);
      const response = await getGroupedChatsAdmin();
      console.log(response, "lpowiu5tp234u5623097")
      setQueries(response.data.data || []);
    } catch (error) {
      console.error('Error fetching queries:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchQueries();
  }, []);

  return (
    <div>
      <SidebarNav />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h1 className="page-title">Customer Queries</h1>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  {loading ? (
                    <div className="text-center">Loading...</div>
                  ) : (
                    <Table responsive striped bordered hover>
                      <thead>
                        <tr>
                          <th>User</th>
                          <th>Phone</th>
                          <th>Provider</th>
                          <th>Provider Phone</th>
                          <th>Action</th>
                          <th>Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {queries.map((query) => (
                          <tr key={query.id}>
                            <td>{query.user.name}</td>
                            <td>{query.user.phone}</td>
                            <td>{query.provider.name}</td>
                            <td>{query.provider.phone}</td>
                            <td>
                              <Button
                                variant="secondary"
                                size="sm"
                                onClick={() => handleViewDetails(query)}
                              >
                                View Details <span className="badge text-danger fs-6 fw-bold">({query.chat_count})</span>
                              </Button>
                            </td>
                            <td>{query.date}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <QueryDetails
        showModal={showModal}
        handleCloseModal={handleCloseModal}
        selectedQuery={selectedQuery}
      />
    </div>
  );
};

export default QueryMsg;
