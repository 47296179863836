import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
// import { fetchChatsByUsers } from '../../../API/QueryChatAPI';

const PaymentDetails = ({ showModal, handleCloseModal, paymentDetails}) => {
console.log(paymentDetails, "line9808")
  return (
    <Modal
    show={showModal}
    onHide={handleCloseModal}
    backdrop="static"
    keyboard={false}
    size="xl"
  >
    <Modal.Header closeButton>
      <Modal.Title>Payment Details</Modal.Title>
    </Modal.Header>
    <Modal.Body>
        {paymentDetails &&
        <div>
        <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
                  {Object.entries(paymentDetails).map(([key, value], index) => (
                    <div
                      key={index}
                      style={{
                        margin: '10px 0',
                        padding: '10px',
                        borderRadius: '8px',
                        backgroundColor: '#f8f9fa',
                        border: '1px solid #dee2e6'
                      }}
                    >
                      <div style={{ fontWeight: 'bold', color: '#0d6efd', marginBottom: '5px' }}>
                        {key}
                      </div>
                      <div style={{ color: '#212529' }}>
                      {typeof value === 'object' ? JSON.stringify(value, null, 2) : value}
                      </div>
                    </div>
                  ))}
                </div>
        </div>
}


    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleCloseModal}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>
  )
}

export default PaymentDetails