import axiosInstanceWithSecret from "../../utils/axiosInstanceWithSecret";

export async function GetActivityByOrder(order_id){
    const response = await axiosInstanceWithSecret.get(`/activity_tracker/user-activities/by_order/?order_id=${order_id}`);
    return response;
 }

 export async function UpdateServiceProviderRefreshDate(serviceProviderId){
    const response = await axiosInstanceWithSecret.put(`/update_service_provider_refresh_date`,{
        serviceProvider_id: serviceProviderId
    });
    return response;
}