import React, { useState } from "react";
import SidebarNav from "../../sidebar";
import { Table } from "antd";
import 'antd/dist/reset.css';
import PaymentDetails from "./PaymentDetails";
import { itemRender, onShowSizeChange } from "../../../components/paginationfunction";

import moment from "moment";
import { Link } from "react-router-dom";

const AllBooking = ({ ordersData }) => {
  const [open, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedPaymentDetails, setSelectedPaymentDetails] = useState(null);

  const handleOpenModal = (paymentDetails) => {
    setSelectedPaymentDetails(paymentDetails);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedPaymentDetails(null);
  };

  const StatusColors = {
    Pending: "badge badge-warning",
    InProgress: "badge badge-warning",
    Scheduled: "badge badge-info",
    PartialyCompleted: "badge badge-secondary",
    Completed: "badge badge-success",
    Cancelled: "badge badge-danger",
    Tickets: "badge badge-secondary",
  };

  const handleShow = () => {
    setOpen(!open);
  };

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      render: (text, record) => <>{record?.short_id}</>,
    },
    {
      title: "Date",
      dataIndex: "date",
      render: (text, record) => (
        <>{moment(record?.updated_at).format("D-MMM yyyy")}</>
      ),
    },
    {
      title: "User Name",
      dataIndex: "user",
      render: (text, record) => (
        <div className="avatar avatar-sm mr-2">
          {record?.userUsername}
        </div>
      ),
      sorter: (a, b) => a.name.length - b.name.length,
    },

    {
      title: "Provider",
      dataIndex: "providername",
      render: (text, record) => (
        <div className="avatar avatar-sm mr-2">
          {record?.serviceProviderUsername}
        </div>
      ),
      sorter: (a, b) => a.providername.length - b.providername.length,
    },

    {
      title: "Service Type",
      dataIndex: "serviceType",
      render: (text, record) => <> {record?.serviceType} </>,
      sorter: (a, b) => a.date.length - b.date.length,
    },
    {
      title: "Amount",
      dataIndex: "amount",
      render: (text, record) => <> {record.charges} </>,
      sorter: (a, b) => a.charges - b.charges,
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      render: (text, record) => (
        <div className="d-flex flex-column">

          <div className={StatusColors?.[record.status]}>
            {record.paymentStatus}
          </div>
          <button
          className=" "
          style={{
            fontSize: "12px", // Reduces the font size
            padding: "1px 3px",
            border: "none" // Adjusts the padding for a smaller clickable area
          }}
          onClick={()=>handleOpenModal(record.phonepe_response)}
        >
          View Details
        </button>
        {showModal &&
        <PaymentDetails showModal={showModal} handleCloseModal={handleCloseModal} paymentDetails={selectedPaymentDetails}/>
          }
        </div>

      ),
      sorter: (a, b) => a.charges.length - b.charges.length,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => (
          <div className={StatusColors?.[record.status]}>
            {record.status}
          </div>
      ),
      sorter: (a, b) => a.charges.length - b.charges.length,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => (
        <Link
          className="btn-link link-primary"
          to={`/admin/bookings/${record.order_id}`}
        >
          View Details
        </Link>
      ),
      sorter: (a, b) => a.updated.length - b.updated.length,
    },
  ];

  return (
    <>
      <SidebarNav />
      <Table
        className="table mb-0"
        style={{ overflowX: "auto", width: "100%" }}
        columns={columns}
        bordered
        dataSource={ordersData}
        rowKey={(record) => record.id}
        showSizeChanger={true}
        pagination={{
          total: ordersData?.length,
          showTotal: (total, range) =>
            `Showing ${range[0]} to ${range[1]} of ${total} entries`,
          showSizeChanger: true,
          onShowSizeChange: onShowSizeChange,
          itemRender: itemRender,
        }}
      />

    </>
  );
};

export default AllBooking;
