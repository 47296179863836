import axiosInstanceWithSecret from "../../utils/axiosInstanceWithSecret";

export async function GetAllPayments(queryParams) {
    const response = await axiosInstanceWithSecret.get(`/payments`, {
        params: queryParams,
    });
    return response;
}

export async function UpdatePaymentStatus(paymentId, body) {
    const response = await axiosInstanceWithSecret.put(`/payments/${paymentId}`, body);
    return response;
}