import React, { useState } from 'react';
import { Modal, Typography, Box, Button, TextField, CircularProgress, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel } from '@mui/material';
import { SendEmail } from '../../../API/EmailAPI';
import Editor from '../Blog/Posts/Editor';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 500,
    maxHeight: '90vh', // Add maximum height of 90% of viewport height
    bgcolor: 'background.paper',
    borderRadius: '8px',
    boxShadow: 24,
    p: 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflowY: 'auto', // Enable vertical scrolling when content exceeds maxHeight
  };

function EmailModal({openModal, handleClose, handleOpen, checkedUsers, emptyCheckedUsers, checkedUsersUserName}) {
    
    const [subject, setSubject] = useState('');
    const [body, setBody] = useState('');
    const [loading, setLoading] = useState(false);
    const [whatsappTemplate, setWhatsappTemplate] = useState('');
  
    const handleSubjectChange = (e) => setSubject(e.target.value);
    const handlePriorityChange = (e) => setWhatsappTemplate(e.target.value);
  
    const handleSendEmail = async () => {

    try{
      setLoading(true);
      await SendEmail(subject, body, checkedUsers, whatsappTemplate);
      setLoading(false);
      // Reset the form
      setSubject('');
      setBody('');
      setWhatsappTemplate('');
      emptyCheckedUsers();
      handleClose(); // Close modal after sending email
      alert("Email/WhatsAPP sent!");
    }
    catch(err){
      console.log(err);
    }
    };


  return (
    <div>
        <Button onClick={handleOpen}
        variant="outlined"
        color='primary'
        >
        Open modal
        </Button>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ mb: 1, textAlign: 'center' }}>
            Send Email
          </Typography>
          {/* <Typography id="modal-modal-description" sx={{ mt: 1, textAlign: 'center', color: 'text.secondary' }}>
            Send an email to the selected users.
          </Typography> */}


          {checkedUsersUserName.length > 0 && (
            <Typography sx={{ my: 1, fontWeight: 'bold' }}>
              Emails will be sent to: {checkedUsersUserName.join(', ')}
            </Typography>
          )}

          {/* Subject Input */}
          <TextField
            label="Subject"
            variant="outlined"
            fullWidth
            sx={{ my: 2 }}
            value={subject}
            onChange={handleSubjectChange}
            required
          />

          <div className='w-full flex flex-row justify-between items-center'>
          <Editor
            placeholder="Type your email here..."
            content={body}
            setContent={setBody}
          />
          </div>

          {/* Email Priority Options */}
          <FormControl sx={{ width: '100%', mt: 2 }}>
            <FormLabel>Whatsapp Template</FormLabel>
            <RadioGroup
              row
              value={whatsappTemplate}
              onChange={handlePriorityChange}
              sx={{ justifyContent: 'space-between' }}
            >
                <FormControlLabel 
                value="" 
                control={<Radio />} 
                label="Mail Only" 
              />
              <FormControlLabel 
                value="HX15cfa3357c80f1edf6ee61e1e3d6697f" 
                control={<Radio />} 
                label="Bookings now open at Mrsolvo.com" 
              />
              <FormControlLabel 
                value="HXd347ca65468834db7ad8d27017ca28f5" 
                control={<Radio />} 
                label="Need Help-Partial Profile" 
              />
              <FormControlLabel 
                value="HXe08ed933a0881d242bc3a0efb8809a29" 
                control={<Radio />} 
                label="Refresh Complete Profile" 
              />
            </RadioGroup>
          </FormControl>

          {/* Send Button */}
          <Button
            variant="contained"
            color="primary"
            onClick={handleSendEmail}
            sx={{ 
                mt: 4,
                width: '100%',
                padding: '10px',
             }}
            disabled={(subject.trim() === '' || body.trim() === '' || checkedUsers.length === 0) && whatsappTemplate === ''}
          >
            {loading ? (
              <CircularProgress size={24} sx={{ color: 'white' }} /> // Show loading spinner
            ) : (
              'Send Email'
            )}
          </Button>
        </Box>
      </Modal>
    </div>

  )
}

export default EmailModal