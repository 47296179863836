import axiosInstanceWithSecret from "../../utils/axiosInstanceWithSecret";

export async function SendEmail(subject, body, to, whatsappTemplate = '') {
    const response = await axiosInstanceWithSecret.post(`send-email`, {
        subject: subject,
        body: body,
        to: to,
        whatsappTemplate: whatsappTemplate
    });
    return response;
}