import React, { useEffect, useState } from "react";
import SidebarNav from "../sidebar";
import { Input, Table, DatePicker } from "antd";
import { Button } from "react-bootstrap";
import { GetAllDocumentsServiceProvider } from "../../../API/DocumentsServiceProviderAPI";
import DetailsModal from "./DetailsModal";
import moment from "moment";

const { Search } = Input;

function Index() {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({
    data: [],
    totalResults: 0,
    current_page: 1,
    page_size: 50,
    sortField: "",
    sortOrder: "",
    filters: {},
    search: "",
    start_date: "",
    end_date: "",
  });
  const [searchedText, setSearchedText] = useState("");
  const [modalDisplayData, setModalDisplayData] = useState("");

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleChange = (pagination, filters, sorter) => {
    const sortField = sorter.field || "";
    const sortOrder =
      sorter.order === "ascend"
        ? "asc"
        : sorter.order === "descend"
        ? "desc"
        : "";
    // setFilteredInfo(filters);
    getData(
      pagination.current,
      pagination.pageSize,
      filters,
      sortField,
      sortOrder,
      searchedText,
      startDate,
      endDate
    );
  };

  const handleSearch = (value) => {
    setSearchedText(value);
    getData(
      data.current_page,
      data.page_size,
      data.filters,
      data.sortField,
      data.sortOrder,
      value,
      startDate,
      endDate
    );
  };

  const handleDateChange = (field, dateString) => {
    if (field === "start_date") {
      setStartDate(dateString);
    } else {
      setEndDate(dateString);
    }
    getData(
      data.current_page,
      data.page_size,
      data.filters,
      data.sortField,
      data.sortOrder,
      searchedText,
      field === "start_date" ? dateString : startDate,
      field === "end_date" ? dateString : endDate
    );
  };

  const getData = async (
    page = 1,
    page_size = 50,
    filters = {},
    sortField = "",
    sortOrder = "",
    search = "",
    start_date = "",
    end_date = ""
  ) => {
    const query = new URLSearchParams({
      page,
      page_size,
      sortField,
      sortOrder,
      search,
      start_date,
      end_date,
      ...filters,
    }).toString();

    const response_data = await GetAllDocumentsServiceProvider(`?${query}`);
    console.log(response_data.data, "\n\n");
    setData({
      data: response_data.data.data,
      totalResults: response_data.data.total_results,
      current_page: response_data.data.current_page,
      page_size: response_data.data.page_size,
      sortField,
      sortOrder,
      filters,
      search,
      start_date,
      end_date,
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      title: "Username",
      dataIndex: "username",
      width: 100,
      // filteredValue: [searchedText],
      render: (text, record) => <>{record?.userId.username}</>,
      // onFilter: (value, record) =>
      //   String(record.username).toLowerCase().includes(value.toLowerCase()),
    },
    {
      title: "Phone",
      width: 120,
      render: (text, record) => <div>{record?.userId.phone}</div>,
    },
    {
      title: "Category",
      width: 120,
      render: (text, record) => (
        <div style={{ whiteSpace: "normal", fontSize: "12px" }}>
          {record?.category}
        </div>
      ),
    },
    {
      title: "Date",
      dataIndex: "updated_at",
      width: 120,
      sorter: true,
      render: (text, record) => (
        <div>{moment(record?.updated_at).format("DD-MMM yyyy")}</div>
      ),
    },
    {
      title: "Pan Comprehensive",
      width: 120,
      render: (text, record) => (
        <Button
          variant="outline-secondary"
          size="sm"
          onClick={() => {
            setOpen(true);
            setModalDisplayData({
              ...record?.pan_verification_details[0].verifiedPanDetails,
              title: "Pan verification details",
            });
          }}
        >
          <div style={{ fontSize: "12px" }}>View Details</div>
        </Button>
      ),
    },
    {
      title: "Face Match",
      width: 120,
      render: (text, record) => (
        <Button
          variant="outline-secondary"
          size="sm"
          onClick={() => {
            setOpen(true);
            setModalDisplayData({
              ...record?.pan_verification_details[0].verifiedFaceMatchDeails,
              panImage: record?.documents?.identityProof,
              selfie: record?.documents?.selfie,
              title: "Face match details",
            });
          }}
        >
          <div style={{ fontSize: "12px" }}>View Details</div>
        </Button>
      ),
    },
    // {
    //   title: "Stage",
    //   dataIndex: "stage",
    //   width: 100,
    //   filters: [
    //     { text: "Partial", value: "Partial" },
    //     { text: "Free", value: "Free" },
    //     { text: "Received", value: "Received" },
    //     { text: "Pending", value: "Pending" },
    //   ],
    //   render: (text, record) => {
    //     return <>{record.stage}</>;
    //   },
    // },
    {
      title: "Other Docs",
      render: (text, record) => (
        <Button
          variant="outline-secondary"
          size="sm"
          onClick={() => {
            setOpen(true);
            const filteredData = {};
            const keysToExclude = [
              "profilePhoto",
              "identityProof",
              "selfie",
              "portfolio",
              "companyProfile",
              "websiteLink",
            ];
            for (const [key, value] of Object.entries(record?.documents)) {
              if (!keysToExclude.includes(key)) {
                filteredData[key] = value;
              }
            }
            filteredData['accepted_agreements'] = record?.userId.agreements
            setModalDisplayData({ ...filteredData, title: "Other Documents" });
          }}
        >
          <div style={{ fontSize: "12px" }}>View Documents Data</div>
        </Button>
      ),
    },
  ];

  return (
    <>
      <SidebarNav />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col">
                <h3 className="page-title">Service Provider Documents</h3>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <Search
                      placeholder="Search here..."
                      style={{
                        marginBottom: 8,
                        border: "1px solid hsla(0, 100%, 40%, 0.1)",
                        borderRadius: "4px",
                      }}
                      onSearch={handleSearch}
                    />
                    <div style={{ display: "flex", marginBottom: 8 }}>
                      <DatePicker
                        placeholder="Start Date"
                        style={{ marginRight: 8 }}
                        onChange={(date, dateString) =>
                          handleDateChange("start_date", dateString)
                        }
                      />
                      <DatePicker
                        placeholder="End Date"
                        onChange={(date, dateString) =>
                          handleDateChange("end_date", dateString)
                        }
                      />
                    </div>
                    <Table
                      className="table table-hover table-center mb-0 datatable rounded-full border-2"
                      style={{ overflowX: "scroll", width: "100%" }}
                      columns={columns}
                      dataSource={data.data}
                      onChange={handleChange}
                      size="large"
                      pagination={{
                        total: data.totalResults,
                        current: data.current_page,
                        pageSize: data.page_size,
                        defaultPageSize: 50,
                        showSizeChanger: true,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {modalDisplayData && (
            <DetailsModal
              open={open}
              setOpen={setOpen}
              modalDisplayData={modalDisplayData}
              setModalDisplayData={setModalDisplayData}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default Index;
